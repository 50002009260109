<template>
  <div>
    <challenges :stage="selectedStage" :item-id="itemId" />
    <rules v-if="mode === 'rules'" />
    <stats v-else-if="mode === 'stats'" />
    <standings
      v-else-if="mode === 'standings'"
      :item-id="itemId"
      :stages="stages"
      :selected-stage="selectedStage"
      @select="onSetSelectedStage"
    />
    <dates v-else-if="mode === 'dates'" />
    <help v-else-if="mode === 'help'" />
    <rules v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Rules from "./Rules.vue";
import Stats from "./Stats.vue";
import Standings from "./Standings.vue";
import Dates from "./Dates.vue";
import Help from "./Help.vue";
import Challenges from "./Challenges.vue";

export default {
  name: "Aero2024AirBattle",
  props: ["item-id", "params"],
  components: {
    Challenges,
    Rules,
    Stats,
    Standings,
    Dates,
    Help,
  },
  data() {
    return {
      selectedStage: null,
    };
  },
  created() {
    this.selectedStage = this.currentStage;
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    mode() {
      return _.get(this.params, "mode");
    },
    currentStage() {
      return _.get(this.params, "stage");
    },
    currentStageIndex() {
      return this.stages.indexOf(this.currentStage);
    },
    stages() {
      return _.get(this.params, "stages");
    },
  },
  methods: {
    onSetSelectedStage(newStage) {
      const indexOfNewStage = this.stages.indexOf(newStage);
      if (indexOfNewStage <= this.currentStageIndex) {
        this.selectedStage = newStage;
      }
    },
  },
};
</script>
