<template>
  <div class="ab__main" v-if="challenges.length > 0">
    <div class="ab__content">
      <v-card v-for="challenge in challenges" :key="challenge._id">
        <v-card-title>Ваша текущая игра</v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
              <tr
                v-for="player in challenge.state.game.players"
                :key="player.user.id"
              >
                <td style="width: 50px">
                  <v-avatar class="ma-2" color="primary">
                    <v-img
                      v-if="player.user.thumbnail"
                      :src="player.user.thumbnail"
                      :alt="player.user.name"
                    />
                    <span v-else class="white--text text-h6">{{
                      getInitials(player.user.name)
                    }}</span>
                  </v-avatar>
                </td>
                <td>
                  <h4>{{ player.user.name }}</h4>
                </td>
                <td>
                  <h2>{{ player.score }}</h2>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <template v-if="challenge.status === 'pending'">
          <v-card-actions>
            <v-btn
              class="ml-2 mb-2 mt-2"
              color="primary"
              @click="onSendChallenge(challenge)"
              >Отправить вызов на бой</v-btn
            >
            <v-btn
              class="ml-2 mb-2 mt-2"
              color="accent"
              @click="onOpenChat(challenge)"
              >Чат с соперником</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  name: "StageChallenges",
  props: ["stage", "item-id"],
  data() {
    return {
      loading: false,
      challenges: [],
      getInitials,
    };
  },
  created() {
    this.fetchChallenges();
  },
  watch: {
    stage() {
      this.fetchChallenges();
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
      activateChallenge: "event/challenge/activateChallenge",
    }),
    ...mapMutations({
      setSelectedChallenge: "event/challenge/setSelectedChallenge",
    }),
    onOpenChat(challenge) {
      this.setSelectedChallenge(challenge);
    },
    onSendChallenge(challenge) {
      this.setSelectedChallenge(challenge);
      this.activateChallenge(challenge._id);
    },
    async fetchChallenges() {
      this.loading = true;
      const { itemId } = this;
      const command = "stage-challenges";
      const params = {
        stage: this.stage,
      };
      try {
        const result = await this.runCommand({
          itemId,
          command,
          params,
        });
        const { challenges } = result;
        this.challenges = challenges;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
  },
};
</script>