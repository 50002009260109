<template>
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
      rel="stylesheet"
    />
    <app-event event-id="aero-2024" auth="login-only" :mapping="mapping">
      <template v-slot:logo>
        <div
          style="
            padding: 1px 4px 4px 0px;
            border-radius: 8px;
            background-color: transparent;
            margin: 16px;
          "
        >
          <v-img :src="require('./assets/logo-20.png')" class="ma-4" />
        </div>
      </template>
      <template v-slot:left-menu>
        <v-navigation-drawer
          app
          :value="drawer || !$vuetify.breakpoint.mobile"
          @input="setDrawer"
          dark
          class="primary"
          :style="{
            backgroundColor: `#6890C2 !important`,
            backdropFilter: 'blur(10px)',
          }"
        >
          <div class="ab__menu-wrapper">
            <div
              class="mb-4"
              style="
                padding: 1px 4px 4px 0px;
                border-radius: 8px;
                background-color: white;
                margin: 0px;
              "
            >
              <v-img :src="require('./assets/logo-20.png')" class="ma-4" />
            </div>
            <div class="ab__menu">
              <div
                v-for="(item, index) in visibleItems"
                :key="index"
                class="ab__menu-item d-flex text-center align-center justify-center"
                :class="{ active: index === selectedItem }"
                @click="onItemSelect(item.id)"
              >
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </v-navigation-drawer>
      </template>
    </app-event>
    <v-dialog :value="user && !userName" :max-width="400" persistent>
      <v-card :loading="updating">
        <v-card-title>Давайте знакомиться 🙂</v-card-title>
        <v-card-text>
          <div>
            Чтобы страна знала своих героев - укажите, пожалуйста, свои имя и
            фамилию
          </div>
        </v-card-text>
        <v-card-text>
          <v-text-field label="Как вас зовут? :)" v-model.trim="name" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!name || name.length < 3"
            :loading="updating"
            @click="onSaveUserName"
            text
            >Сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventMenuMixin from "../../mixins/eventMenuMixin";
import themeMixin from "@/mixins/eventTheme";
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";
import Shop from "./Shop/Index.vue";
import AirBattle from "./AirBattle/Index.vue";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "AppAero2024",
  components: {
    AppEvent,
  },
  mixins: [eventMenuMixin, themeMixin],
  data() {
    return {
      name: "",
      updating: false,
      mapping: {
        quest: Quest,
        shop: Shop,
        "air-battle": AirBattle,
      },
    };
  },
  created() {
    this.name = this.userName;

    const favicon = document.getElementById("favicon");
    favicon.type = "image/svg";
    favicon.href = "/aero-2024/alive.svg";
  },
  watch: {
    userName() {
      this.name = this.userName;
    },
  },
  computed: {
    ...mapGetters({
      user: "event/user",
      isAdmin: "event/isAdmin",
    }),
    ...mapState(["drawer"]),
    profile() {
      return _.get(this.user, "profile");
    },
    userName() {
      return _.get(this.profile, "name");
    },
  },
  methods: {
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
    }),
    ...mapMutations({
      setDrawer: "drawer",
    }),
    async onSaveUserName() {
      const update = { name: this.name };
      this.updating = true;
      await this.updateMyProfile(update);
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body {
  height: 100%;
  color: #12275c;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  background-color: #efeeee !important;
}
</style>
<style>
.img-responsive {
  max-width: 100%;
  height: auto;
}
.ab__main {
  width: 100%;
  height: 100%;
}
.ab__menu-wrapper {
  background: #12275c;
  box-sizing: border-box;
  width: 256px;
  height: 100%;
  padding: 20px 13px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.ab__menu-logo {
  background: #fff;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 60px;
  height: 230px;
}
.ab__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ab__menu-item {
  box-sizing: border-box;
  height: 40px;
  padding: 10px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  /* text-align: center;
  vertical-align: middle; */
}
.ab__menu-item.active {
  background: #16a3d9;
}
.ab__menu-item:hover {
  cursor: pointer;
}
.ab__menu-item > span {
  line-height: 19.36px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.ab__content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 60px 120px;
  overflow: auto;
  background: #efeeee;
}
.ab__content-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  color: #12275c;
  gap: 20px;
  font-weight: 700;
  font-size: 60px;
  line-height: 72.61px;
  letter-spacing: -2px;
  text-transform: uppercase;
}
.ab__content-header-profile {
  margin-bottom: 30px;
}
.ab__content-header img[src="img/ab-ap-v4.png"] {
  height: 51.46px;
  width: auto;
}
.ab__content-header img {
  height: 72.61px;
  width: auto;
}
.ab__content-controls {
  display: flex;
  flex-direction: row;
  gap: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  color: rgba(18, 39, 92, 0.5);
}
.ab__content-controls-item {
  cursor: pointer;
}
.ab__content-controls-item.active {
  color: rgba(18, 39, 92, 1);
}
.ab__content-table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 100%;
}
.ab__content-table,
.ab__content-table td,
.ab__content-table td {
  border: 1px solid #12275c;
}
.ab__content-table td {
  background: #fff;
}
.ab__content-table thead {
  text-transform: uppercase;
  color: #fff;
}
.ab__content-table th {
  background: #16a3d9;
  font-weight: 400;
  font-size: 20px;
  line-height: 24.2px;
  border: 1px solid #12275c;
}
.ab__content-table td {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
}
.ab__content-table td,
.ab__content-table th {
  outline: 0;
  padding: 10px;
  vertical-align: middle;
}
.ab__textblock-item {
  background: #fff;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
}
.ab__textblock-item-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #16a3d9;
}
.ab__textblock-item-list {
  padding-left: 15px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.ab__profile {
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-bottom: 50px;
  max-width: 1000px;
}
.ab__profile-person {
  width: 200px;
  height: 200px;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}
.ab__profile-personphoto {
  border-radius: 10px;
}
.ab__profile-personrating {
  position: absolute;
  z-index: 2;
  top: -22px;
  right: -48px;
  width: 109px;
  height: 55px;
  background-image: url(./assets/ab-profilerating.png);
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
}
.ab__profile-personrating > span {
  line-height: 65px;
  /* position: absolute;
    top: 23px;
    left: 39px; */
}
.ab__profile-info h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  margin-bottom: 15px;
}
.ab__profile-info p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  margin-bottom: 15px;
}
.ab__profile-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
.ab__button {
  padding: 10px 30px;
  border: 1px solid #16a3d9;
  border-radius: 10px;
  color: #fff;
  font-family: "Inter", sans-serif;
  background: #16a3d9;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  flex-shrink: 0;
}
.ab__btn-gray {
  background: #efeeee;
  color: #16a3d9;
}
.ab__content-battle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 25px;
  margin: 30px 0;
}
@media (width <= 800px) {
  .ab__content-battle {
    flex-direction: column;
  }
}
.ab__content-bf-player {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ab__content-bf-player-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
}
.ab__content-bf-player-score {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #a10202;
}
.ab__content-bf {
  width: 425px;
  height: 423px;
}
.ab__content-bf.bf-await {
  filter: blur(5px);
}
.ab__content-status-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
}
.ab__content-bf-status {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  width: 400px;
  height: 400px;
  padding: 0 40px;
  bottom: 0;
  right: 0;
}
.ab__content-bf-status h4 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36.31px;
  letter-spacing: -2px;
  text-align: center;
}
.ab__content-bf-status p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
}
.ab__content-bf-turn {
  width: 155px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
.ab__content-bf-turn > img {
  height: 85px;
  width: 85px;
}
.ab__content-bf-turn > h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
  text-transform: uppercase;
}
@media (width <= 1000px) {
  .ab__content {
    padding: 30px 40px;
  }
}
@media (width <= 500px) {
  .ab__content {
    padding: 30px 20px;
  }
  .ab__content-header {
    font-size: 40px;
    line-height: 42px;
    gap: 5px;
  }
  .ab__content-header img {
    height: 42px;
    width: auto;
  }
  .ab__content-table {
  }
}
</style>