<template>
  <div class="ab__mainsfs">
    <div class="ab__content">
      <h1 class="ab__content-header">
        <span>Турнирная таблица</span><img src="../assets/plane-logo.svg" />
      </h1>
      <div class="ab__content-controls">
        <span
          v-for="stage in stages"
          :key="stage"
          :class="{ active: selectedStage === stage }"
          class="ab__content-controls-item"
          @click="$emit('select', stage)"
        >
          {{ stage }}
        </span>
      </div>
      <table class="ab__content-table">
        <thead>
          <tr>
            <th class="text-left">Место</th>
            <th class="text-left">&nbsp;</th>
            <th>Участник</th>
            <th>Баллы</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in displayUsers"
            :key="`player-standings-item-${user._id}`"
          >
            <td class="text-center" style="width: 50px">
              {{ user.index }}
            </td>
            <td style="width: 50px">
              <v-avatar class="ma-2" color="#38b6b4">
                <v-img
                  v-if="user.profile && user.profile.thumbnail"
                  :src="user.profile.thumbnail"
                  :alt="user.name"
                />
                <span v-else class="white--text text-h6">{{
                  user.initials
                }}</span>
              </v-avatar>
            </td>
            <td>{{ user.name }}</td>
            <td class="text-center">{{ user.points }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog
      v-model="userDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 400"
    >
      <v-card v-if="selectedUser">
        <v-img
          v-if="selectedUser.profile.image"
          :src="selectedUser.profile.image"
        ></v-img>
        <v-card-title>
          {{ selectedUser.name }}
        </v-card-title>
        <v-card-text>
          <div v-if="selectedUser.profile.about" class="mt-2">
            О себе:
            <br />
            {{ selectedUser.profile.about }}
          </div>
          <div v-if="selectedUser.profile.birthCity" class="mt-2">
            Город рождения:
            <br />
            {{ selectedUser.profile.birthCity }}
          </div>
          <div v-if="selectedUser.profile.interestingFact" class="mt-2">
            Интересный факт:
            <br />
            {{ selectedUser.profile.interestingFact }}
          </div>
        </v-card-text>
        <v-card-actions class="pb-4 pt-0 mt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="userDialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  props: ["item-id", "selected-stage", "stages"],
  data() {
    return {
      users: [],
      items: [],
      loading: false,

      selectedUser: null,
      userDialog: false,
    };
  },
  created() {
    this.fetchStandings();
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    userId() {
      return _.get(this.user, "id");
    },
    displayUsers() {
      return this.users.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          initials: getInitials(item.name),
        };
      });
    },
  },
  watch: {
    selectedStage() {
      this.fetchStandings();
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
    }),
    ...mapMutations({
      openChallengeDialog: "event/challenge/openChallengeDialog",
    }),
    async fetchStandings() {
      this.loading = true;
      const { itemId } = this;
      const command = "standings";
      const params = { stage: this.selectedStage };
      try {
        const result = await this.runCommand({
          itemId,
          command,
          params,
        });
        const { users, items } = result;
        this.users = users;
        this.items = items;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
    async onUserClick(user) {
      if (user == null) return;
      this.selectedUser = user;
      this.userDialog = true;
    },
    onSendChallenge(user) {
      const usr = {
        id: user._id,
        name: user.profile.name,
        thumbnail: user.profile.thumbnail,
        image: user.profile.image,
      };
      this.openChallengeDialog(usr);
      this.userDialog = false;
    },
  },
};
</script>